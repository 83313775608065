import React from 'react'

const CadenasIcon = () => (
	<svg
		width="21"
		height="26"
		viewBox="0 0 21 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.0488 10.3345H17.8959V7.67284C17.8959 3.44198 14.5083 0 10.3286 0C6.14902 0 2.76138 3.44198 2.76138 7.67284V10.3345H2.66161C1.194 10.3374 0.00435614 11.5255 0 12.9932V23.3241C0 24.7882 1.19753 26 2.66161 26H18.0488C19.5129 26 20.7104 24.7882 20.7104 23.3241V12.9932C20.706 11.5255 19.5164 10.3371 18.0488 10.3345ZM3.82346 7.67284C3.82346 4.02778 6.73482 1.06207 10.3286 1.06207C13.9227 1.06207 16.8338 4.02778 16.8338 7.67284V10.3345H15.7718V7.67284C15.7718 4.61337 13.3369 2.12414 10.3286 2.12414C7.32041 2.12414 4.88553 4.61337 4.88553 7.67284V10.3345H3.82346V7.67284ZM14.7097 10.3345H5.9476V7.67284C5.9476 5.19896 7.906 3.18621 10.3286 3.18621C12.7513 3.18621 14.7097 5.19876 14.7097 7.67284V10.3345ZM19.6483 23.3448C19.6483 24.2248 18.9352 24.9379 18.0552 24.9379H2.65518C1.77544 24.9379 1.06207 24.2248 1.06207 23.3448V12.9896C1.06207 12.1099 1.77544 11.3965 2.65518 11.3965H18.0552C18.9352 11.3965 19.6483 12.1099 19.6483 12.9896V23.3448Z"
			fill="#172B49"
		/>
		<circle cx="10.2998" cy="18" r="5" fill="#FFEDBD" />
		<path
			d="M10.3393 15.5648C9.29296 15.5683 8.41178 16.3482 8.28109 17.3865C8.15041 18.4247 8.81088 19.3986 9.82379 19.6614V21.9448C9.82379 22.2382 10.0615 22.4759 10.3548 22.4759C10.6481 22.4759 10.8859 22.2382 10.8859 21.9448V19.6614C11.8004 19.422 12.4362 18.5929 12.4298 17.6474C12.4248 16.4961 11.4905 15.5654 10.3393 15.5648ZM10.3393 18.6682C9.77567 18.6682 9.31869 18.2112 9.31869 17.6474C9.31869 17.0838 9.77567 16.6268 10.3393 16.6268C10.9031 16.6268 11.3601 17.0838 11.3601 17.6474C11.3594 18.2108 10.9029 18.6676 10.3393 18.6682Z"
			fill="#172B49"
		/>
	</svg>
)
export default CadenasIcon
