import React from 'react'
import { TMarketItem } from '@coq-trotteur/coq-types'

import DecoratedHeading from '~components/Common/DecoratedHeading/DecoratedHeading'
import { useBoutiqueItemContext } from '../../BoutiqueItem'

import { capitalize } from '~utils/misc'

import './BoutiqueItemInformations.scss'

const BoutiqueItemInformations = ({ typo }) => {
	const item = useBoutiqueItemContext()

	return (
		<div className="BoutiqueItemInformations" style={{ ...typo?.p }}>
			<DecoratedHeading h={2} style={{ ...typo?.h3 }}>
				Informations
			</DecoratedHeading>

			<CancellationPolicy offer={item} />
			<Inclusions offer={item} />
			<PlaceOfSupport offer={item} />
			<Notes offer={item} />

			{item.type === 'service' && (
				<MarketItemInformationsLine
					label="Durée de validité"
					value={`${item.time_limit} mois`}
					style={{ ...typo?.p }}
				/>
			)}

			{item.type === 'product' && (
				<MarketItemInformationProductDimension
					characteristics={item.characteristics}
					style={{ ...typo?.p }}
				/>
			)}

			{item.type === 'product' && item.characteristics.weight && (
				<MarketItemInformationsLine
					label="Poids"
					value={`${item.characteristics.weight}g`}
					style={{ ...typo?.p }}
				/>
			)}
			{item.type === 'product' && (
				<MarketItemDelivery delivery={item.delivery} style={{ ...typo?.p }} />
			)}

			{item.category.name === 'Vins' && (
				<div
					style={{
						marginTop: 10
					}}
				>
					<u>Rappel</u> : La vente d’alcool est interdite aux moins de 18 ans.
					L’abus d’alcool est dangereux pour la santé et déconseillé aux femmes
					enceintes. À consommer avec modération.
				</div>
			)}
		</div>
	)
}

export default BoutiqueItemInformations

const MarketItemInformationsLine = ({ label, value, style }) => (
	<div className="MarketItemInformationsLine" style={style}>
		<span className="label">{label}</span>
		{' : '}
		<span>{value}</span>
	</div>
)

const MarketItemInformationProductDimension = ({ characteristics, style }) => {
	const sizes = []

	if (characteristics.height) sizes.push(`hauteur ${characteristics.height}cm`)
	if (characteristics.length) sizes.push(`longeur ${characteristics.length}cm`)
	if (characteristics.width) sizes.push(`profondeur ${characteristics.width}cm`)
	if (characteristics.volume)
		sizes.push(`contenance ${characteristics.volume}L`)

	if (!sizes.length) return null

	return (
		<MarketItemInformationsLine
			label="Dimension"
			value={capitalize(sizes.join(' ; '))}
			style={style}
		/>
	)
}

const MarketItemDelivery = ({ delivery, style }) => {
	const options = []

	const formatPreparationDelay = (delay) => {
		if (delay === 'some_minutes') return 'quelques minutes'

		const [n, unit] = delay.split('_')

		const units = {
			hour: 'heure',
			minutes: 'minute',
			day: 'jour'
		}

		return `${n} ${units[unit]}${n > 1 ? 's' : ''}`
	}

	if (delivery.takeAway.enabled) {
		options.push(
			`Retrait sur place : ${formatPreparationDelay(
				delivery.takeAway.preparationDelay
			)}`
		)
	}

	if (delivery.ownDelivery.enabled) {
		options.push(
			`Livraison à domicile par le vendeur : ${formatPreparationDelay(
				delivery.ownDelivery.preparationDelay
			)}`
		)
	}

	if (delivery.shippingOptions) {
		delivery.shippingOptions.forEach((option) => {
			options.push(
				`Livraison par ${
					option.service
				}, délai avant expédition : ${formatPreparationDelay(
					option.preparationDelay
				)}`
			)
		})
	}

	if (!options.length) return null

	return (
		<MarketItemInformationsLine
			label="Délai estimé pour récupérer le produit après achat"
			value={options.map((option, i) => (
				<div key={i}>{option}</div>
			))}
			style={style}
		/>
	)
}

const CancellationPolicy: React.FC<{ offer: TMarketItem }> = ({ offer }) => {
	return (
		<div>
			<u>Politique d'annulation</u> : {getCancellationPolicyText(offer)}
			{offer.type === 'product' && (
				<div>
					<div>
						<u>Retour</u> :{' '}
						{offer.reservation_policy?.refundPolicy?.enabled
							? `Retour: ${offer.reservation_policy.exchangePolicy.delay} jours après achat`
							: `Aucun retour possible`}
					</div>
					<div>
						<u>Echange</u> :{' '}
						{offer.reservation_policy?.exchangePolicy.enabled
							? `Échange: ${offer.reservation_policy.exchangePolicy.delay} jours après achat`
							: `Aucun échange possible`}
					</div>
				</div>
			)}
			{offer.type === 'service' &&
				offer.reservation_policy.weather_cancellation && (
					<div>Annulation possible en cas de mauvais temps</div>
				)}
		</div>
	)
}

const Inclusions: React.FC<{ offer: TMarketItem }> = ({ offer }) => {
	const { has, hasnt } = offer.about.inclusions

	return (
		<div>
			{has?.length > 0 && (
				<div>
					<u>Inclus</u> : {has.join(', ')}
				</div>
			)}

			{hasnt?.length > 0 && (
				<div>
					<u>N'inclus pas</u> : {hasnt.join(', ')}
				</div>
			)}
		</div>
	)
}

const PlaceOfSupport: React.FC<{ offer: TMarketItem }> = ({ offer }) => {
	if (
		offer.type !== 'service' ||
		!offer.service_location.enabled ||
		!offer.service_location.address?.fullAddress
	)
		return null

	return (
		<div>
			Lieu de prise en charge : {offer.service_location.address.fullAddress}
		</div>
	)
}

const Notes: React.FC<{ offer: TMarketItem }> = ({ offer }) => (
	<div>
		{offer.type === 'service' && (
			<div>
				{offer.service_location.note && (
					<div>
						<u>Note</u> : {offer.service_location.note}
					</div>
				)}
				{offer.service_location.instructions && (
					<div>
						<u>Instructions</u> :{offer.service_location.instructions}
					</div>
				)}
			</div>
		)}
	</div>
)

export const getCancellationPolicyText = (offer: TMarketItem) => {
	if (offer.type === 'service') {
		const { cancellation } = offer.reservation_policy

		if (cancellation === 'standard')
			return `Annulation gratuite jusque 24h avant le début de l'activité`
		if (cancellation === 'partial')
			return `Annulation gratuite jusqu'à 4j avant le début de l'activité.
			  Annulation avec remboursement à 50% entre 4 jours et 2 jours.
			  Non annulable à moins de 2 jours du début de l'activité.`
		return 'Non annulable'
	}
	const { enabled, delay } = offer.reservation_policy.cancellationPolicy

	if (enabled === false || !delay) return `Non annulable`

	return `Annulable ${delay < 3 ? `${delay * 24}h` : `${delay}j`} après achat`
}
