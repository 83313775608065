import { TMarketItem, TService } from '@coq-trotteur/coq-types'
import React, { Fragment } from 'react'
import { useBoutiqueItemContext } from '../../BoutiqueItem'

import './BoutiqueItemSubtitle.scss'

const BoutiqueItemSubtitle = ({ errors }) => {
	const offer = useBoutiqueItemContext()

	const titleParticules = [
		offer.type === 'service' && hasServiceDuration(offer)
			? formatServiceDuration(offer)
			: null,

		offer.type === 'service' && offer.private_group?.enabled ? (
			<span>
				<span
					className={`private-group ${errors?.private_group ? 'error' : ''}`}
				>
					Groupe privé de {offer.private_group.min_visitor_count} à{' '}
					{offer.private_group.max_visitor_count} personnes
				</span>
			</span>
		) : (
			''
		),
		offer.reservation_policy.confirmation_method === 'manual'
			? 'Validation manuelle : le professionnel a 48h pour valider la réservation'
			: null
	].filter((p) => !!p)

	return (
		<div className="BoutiqueItemSubtitle">
			{titleParticules.map((particule, i) => (
				<Fragment key={i}>
					{i > 0 ? ' - ' : ''}
					{particule}
				</Fragment>
			))}
		</div>
	)
}

export default BoutiqueItemSubtitle

export const hasServiceDuration = (marketItem: TMarketItem) => {
	return (
		marketItem.type === 'service' &&
		marketItem.service_duration &&
		(marketItem.service_duration.days ||
			marketItem.service_duration.hours ||
			marketItem.service_duration.minutes)
	)
}

export const formatServiceDuration = (service: TService) => {
	const { days, hours, minutes } = service.service_duration

	const particles = []

	if (days) particles.push(`${days}j`)
	if (hours) particles.push(`${hours}h`)
	if (minutes) particles.push(`${minutes}m`)

	return 'Durée : ' + particles.join(' ')
}
