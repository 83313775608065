import * as React from 'react'

export const GiftIcon = () => {
	return (
		<svg
			width="30"
			height="32"
			viewBox="0 0 30 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.36589 4.91513C2.08534 4.91513 1.85742 4.68721 1.85742 4.40666V2.54235C1.85742 2.26179 2.08534 2.03387 2.36589 2.03387C2.6467 2.03387 2.87437 2.26179 2.87437 2.54235V4.40666C2.87437 4.68771 2.6467 4.91513 2.36589 4.91513Z"
				fill="#172B49"
			></path>
			<path
				d="M3.30759 3.98289H1.50261C1.22181 3.98289 0.994141 3.75497 0.994141 3.47441C0.994141 3.19361 1.22181 2.96594 1.50261 2.96594H3.30759C3.58839 2.96594 3.81606 3.19361 3.81606 3.47441C3.81606 3.75497 3.58864 3.98289 3.30759 3.98289Z"
				fill="#172B49"
			></path>
			<path
				d="M28.3435 28.1693C28.3435 27.6881 27.9493 27.2981 27.4634 27.2981C26.9773 27.2981 26.583 27.6881 26.583 28.1693C26.583 28.6505 26.9773 29.0405 27.4634 29.0405C27.9493 29.0405 28.3435 28.6505 28.3435 28.1693Z"
				fill="white"
			></path>
			<path
				d="M27.4641 29.549C26.6981 29.549 26.0752 28.9303 26.0752 28.1695C26.0752 27.4088 26.6981 26.7899 27.4641 26.7899C28.2295 26.7899 28.8524 27.4088 28.8524 28.1695C28.8524 28.9303 28.2295 29.549 27.4641 29.549ZM27.4641 27.8068C27.259 27.8068 27.0921 27.9694 27.0921 28.1695C27.0921 28.3694 27.259 28.532 27.4641 28.532C27.6689 28.532 27.8355 28.3694 27.8355 28.1695C27.8355 27.9694 27.6689 27.8068 27.4641 27.8068Z"
				fill="#172B49"
			></path>
			<path
				d="M27.6015 18.7283C27.3217 18.7283 27.0938 18.4986 27.0938 18.2156C27.0938 17.9323 27.3192 17.7026 27.5987 17.7026H27.6015C27.881 17.7026 28.1077 17.9323 28.1077 18.2156C28.1077 18.4986 27.881 18.7283 27.6015 18.7283Z"
				fill="#172B49"
			></path>
			<path
				d="M0.507727 23.3045C0.228167 23.3045 0 23.0748 0 22.7918C0 22.5085 0.225436 22.2789 0.505244 22.2789H0.507727C0.787287 22.2789 1.01421 22.5085 1.01421 22.7918C1.01421 23.0748 0.787287 23.3045 0.507727 23.3045Z"
				fill="#172B49"
			></path>
			<path
				d="M25.7036 4.71327C25.5747 4.71327 25.4458 4.66461 25.347 4.56704C25.1469 4.3704 25.1444 4.04814 25.3416 3.84803L27.5197 1.63538C27.7161 1.43552 28.0386 1.43303 28.2387 1.63017C28.4388 1.82705 28.4413 2.14907 28.2439 2.34918L26.066 4.56182C25.9665 4.66262 25.8349 4.71327 25.7036 4.71327Z"
				fill="#172B49"
			></path>
			<path
				d="M24.663 5.76103C24.5945 5.76103 24.5309 5.74812 24.4674 5.72279C24.4063 5.69747 24.3504 5.65924 24.3043 5.61107C24.2077 5.51722 24.1543 5.38737 24.1543 5.25281C24.1543 5.21978 24.1568 5.18652 24.1645 5.15325C24.1697 5.12023 24.1799 5.0872 24.1925 5.05667C24.2052 5.02613 24.2201 4.99857 24.2404 4.97027C24.2583 4.94246 24.2787 4.91689 24.304 4.89405C24.4922 4.70337 24.8328 4.70337 25.021 4.89405C25.0464 4.91689 25.0667 4.94221 25.0846 4.97027C25.1025 4.99857 25.1201 5.02613 25.1328 5.05667C25.1457 5.0872 25.1529 5.12023 25.1606 5.15325C25.1683 5.18652 25.1707 5.21978 25.1707 5.25281C25.1707 5.38737 25.1174 5.51722 25.0208 5.61107C24.9274 5.70765 24.7978 5.76103 24.663 5.76103Z"
				fill="#172B49"
			></path>
			<path
				d="M27.3129 6.62127C27.1841 6.62127 27.0552 6.5726 26.9564 6.47503C26.7563 6.27839 26.7538 5.95613 26.9509 5.75602L29.1291 3.54362C29.3255 3.34351 29.648 3.34102 29.8481 3.53816C30.0482 3.73529 30.0507 4.05706 29.8533 4.25717L27.6754 6.46982C27.5759 6.57062 27.4443 6.62127 27.3129 6.62127Z"
				fill="#172B49"
			></path>
			<path
				d="M26.2724 7.66776C26.2036 7.66776 26.1401 7.65485 26.0765 7.62952C26.0157 7.6042 25.9596 7.56845 25.9136 7.52028C25.8883 7.49446 25.8679 7.46938 25.8476 7.44108C25.8297 7.41352 25.8146 7.38522 25.8019 7.35468C25.789 7.32414 25.7788 7.29385 25.7739 7.26058C25.7662 7.22756 25.7637 7.19454 25.7637 7.15929C25.7637 7.12627 25.7662 7.093 25.7739 7.05973C25.7791 7.02944 25.7892 6.99617 25.8019 6.96588C25.8146 6.93559 25.8295 6.90505 25.8498 6.87675C25.8677 6.84894 25.8881 6.82337 25.9134 6.80053C26.1016 6.61233 26.4422 6.60985 26.6304 6.80053C26.6557 6.82337 26.6761 6.84869 26.694 6.87675C26.7116 6.9048 26.7295 6.93534 26.7419 6.96588C26.755 6.99617 26.7622 7.02944 26.7699 7.05973C26.7776 7.093 26.7801 7.12627 26.7801 7.15929C26.7801 7.19479 26.7776 7.22781 26.7699 7.26058C26.765 7.29385 26.7548 7.32414 26.7419 7.35468C26.7292 7.38522 26.7143 7.41327 26.694 7.44108C26.6761 7.46914 26.6557 7.49446 26.6304 7.52028C26.5343 7.61686 26.4072 7.66776 26.2724 7.66776Z"
				fill="#172B49"
			></path>
			<path
				d="M16.4814 3.67401C16.4861 5.40698 13.8248 9.27067 13.8248 9.27067C13.8248 9.27067 11.1424 5.35261 11.1377 3.61964C11.133 1.88641 12.3255 0.493828 13.801 0.508476C15.2765 0.523373 16.4764 1.94079 16.4814 3.67401Z"
				fill="white"
			></path>
			<path
				d="M13.8255 9.77914C13.8252 9.77914 13.825 9.77914 13.8247 9.77914C13.6571 9.77889 13.5005 9.69622 13.4059 9.55793C13.1224 9.1438 10.6351 5.45788 10.6299 3.62112C10.6272 2.5647 11.0222 1.56464 11.7144 0.877908C12.2924 0.303891 13.0429 0.000496554 13.8066 0C15.5567 0.017876 16.9846 1.66544 16.9903 3.67227C16.9952 5.51126 14.5259 9.15026 14.2443 9.55892C14.1495 9.69696 13.9928 9.77914 13.8255 9.77914ZM13.7778 1.01694C13.2805 1.01694 12.8152 1.21805 12.4307 1.59965C11.9304 2.0962 11.6446 2.8321 11.6468 3.61814C11.6501 4.70609 12.9761 7.01458 13.8262 8.34783C14.6691 7.03146 15.9763 4.75848 15.9733 3.67525C15.9693 2.22158 14.9929 1.02911 13.7964 1.01694C13.7902 1.01694 13.784 1.01694 13.7778 1.01694Z"
				fill="#172B49"
			></path>
			<path
				d="M17.4237 4.74784C15.8429 5.4671 13.4336 9.49191 13.4336 9.49191C13.4336 9.49191 18.1226 10.2876 19.7036 9.56863C21.2844 8.84937 22.0558 7.18716 21.4264 5.8564C20.7965 4.52489 19.0044 4.02883 17.4237 4.74784Z"
				fill="white"
			></path>
			<path
				d="M17.5189 10.3787C15.6988 10.3787 13.6761 10.0488 13.3491 9.99289C13.1835 9.96508 13.0425 9.85708 12.9725 9.70439C12.9022 9.55195 12.9117 9.37443 12.9978 9.23043C13.2528 8.80463 15.537 5.04745 17.2138 4.28524C19.0434 3.45252 21.1398 4.0598 21.8864 5.63835C22.2149 6.33253 22.2419 7.13472 21.9631 7.89668C21.6274 8.81357 20.8806 9.59167 19.9148 10.0314C19.3445 10.2908 18.4587 10.3787 17.5189 10.3787ZM14.2755 9.10505C15.8458 9.31931 18.5021 9.55642 19.4937 9.10604C20.2133 8.77856 20.7654 8.2105 21.008 7.54736C21.1954 7.03566 21.1813 6.52594 20.9673 6.07358C20.4566 4.99432 18.9619 4.60725 17.6351 5.21082C16.647 5.6602 15.1231 7.79141 14.2755 9.10505ZM17.4244 4.74803H17.4268H17.4244Z"
				fill="#172B49"
			></path>
			<path
				d="M10.1956 4.7478C11.7766 5.46706 14.1856 9.49188 14.1856 9.49188C14.1856 9.49188 9.49667 10.2876 7.91564 9.5686C6.33486 8.84934 5.56346 7.18713 6.19309 5.85636C6.82248 4.52535 8.61479 4.02879 10.1956 4.7478Z"
				fill="white"
			></path>
			<path
				d="M10.101 10.3788C9.161 10.3788 8.2754 10.2906 7.7051 10.0314C6.7393 9.59172 5.99298 8.81411 5.65706 7.89673C5.378 7.13501 5.40506 6.33283 5.73353 5.6384C6.4801 4.05985 8.57606 3.45232 10.4061 4.28529C12.0832 5.0475 14.3669 8.80468 14.6219 9.23048C14.708 9.37448 14.7174 9.55224 14.6474 9.70444C14.5772 9.85663 14.4361 9.96488 14.2705 9.99294C13.9436 10.0488 11.9206 10.3788 10.101 10.3788ZM8.69672 4.92584C7.80193 4.92584 6.99975 5.33972 6.65266 6.07338C6.43864 6.52599 6.42424 7.0357 6.61169 7.5474C6.85451 8.2103 7.40643 8.77861 8.12618 9.10609C9.11904 9.55696 11.7746 9.31936 13.3447 9.1051C12.4971 7.79171 10.9729 5.66025 9.98528 5.21111C9.55998 5.0177 9.1183 4.92584 8.69672 4.92584Z"
				fill="#172B49"
			></path>
			<path
				d="M3.18457 14.9401H24.4356V31.0168H3.18457V14.9401Z"
				fill="white"
			></path>
			<path
				d="M24.4355 31.5253H3.18425C2.90345 31.5253 2.67578 31.2974 2.67578 31.0168V14.9404C2.67578 14.6598 2.90345 14.4319 3.18425 14.4319H24.4355C24.7161 14.4319 24.944 14.6598 24.944 14.9404V31.0168C24.944 31.2974 24.7163 31.5253 24.4355 31.5253ZM3.69272 30.5083H23.9271V15.4488H3.69272V30.5083Z"
				fill="#172B49"
			></path>
			<path
				d="M1.65039 9.46704H25.9679V14.9403H1.65039V9.46704Z"
				fill="white"
			></path>
			<path
				d="M25.9686 15.4488H1.65105C1.37025 15.4488 1.14258 15.2212 1.14258 14.9404V9.46685C1.14258 9.18604 1.37025 8.95837 1.65105 8.95837H25.9686C26.2494 8.95837 26.477 9.18604 26.477 9.46685V14.9401C26.477 15.2212 26.2494 15.4488 25.9686 15.4488ZM2.15952 14.4319H25.4601V9.97532H2.15952V14.4319Z"
				fill="#172B49"
			></path>
			<path
				d="M8.84842 19.779C9.15976 19.1796 9.66426 18.7342 10.308 18.4909C11.716 17.9584 12.7494 18.4023 13.3678 18.8683C13.5404 18.9982 13.6831 19.1357 13.81 19.27C13.9369 19.1357 14.0794 18.9982 14.2519 18.8683C14.8709 18.4023 15.9037 17.9581 17.3117 18.4909C17.9555 18.7345 18.4602 19.1799 18.7713 19.779C19.0424 20.3011 19.1639 20.9322 19.1221 21.6036C19.0139 23.3547 17.9602 24.4846 16.9142 25.4489L16.2563 26.045C15.4278 26.7886 14.676 27.4431 13.9659 28.0382L13.8097 28.1693L13.6533 28.0382C12.9433 27.4431 12.1915 26.7886 11.3632 26.045L10.7053 25.4489C9.65905 24.4846 8.60536 23.3547 8.49711 21.6036C8.45589 20.9322 8.57755 20.3011 8.84842 19.779Z"
				fill="#FF0600"
			></path>
			<path
				d="M13.8095 28.678C13.6933 28.678 13.5771 28.6383 13.4825 28.5588L13.3261 28.4275C12.6078 27.8257 11.8543 27.17 11.0231 26.4232L10.3634 25.8258C9.2767 24.824 8.11054 23.5943 7.98938 21.6347C7.94221 20.8717 8.08298 20.149 8.39655 19.5447C8.76649 18.8321 9.36483 18.303 10.1275 18.0148C11.7518 17.4008 12.9522 17.9189 13.6732 18.4619C13.7191 18.4967 13.7641 18.5322 13.8095 18.5699C13.8544 18.5324 13.8999 18.4964 13.946 18.4617C14.6663 17.9182 15.8662 17.4005 17.4914 18.0148C18.2539 18.303 18.8527 18.8321 19.2227 19.5447C19.5358 20.149 19.6768 20.8717 19.6296 21.6347C19.5084 23.5946 18.3425 24.824 17.2588 25.8231L16.5976 26.4217C15.7662 27.1683 15.0129 27.8239 14.2926 28.428L14.1367 28.5588C14.0419 28.6383 13.9257 28.678 13.8095 28.678ZM9.29904 20.0132C9.07038 20.4538 8.96858 20.9931 9.00409 21.5723C9.10191 23.1558 10.068 24.1713 11.0491 25.0753L11.7039 25.6682C12.4594 26.3467 13.1506 26.95 13.8092 27.5057C14.4694 26.9488 15.1604 26.3455 15.9164 25.6669L16.5723 25.0725C17.5505 24.1713 18.5163 23.1558 18.6142 21.5723C18.6502 20.9934 18.5481 20.4541 18.3194 20.0132C18.0672 19.5268 17.6563 19.1645 17.1314 18.9664C16.1751 18.6047 15.3088 18.708 14.5573 19.2748C14.4309 19.3694 14.3142 19.4759 14.1789 19.6191C14.0826 19.7209 13.9493 19.7785 13.8092 19.7785C13.6692 19.7785 13.5356 19.7209 13.4398 19.6194C13.3038 19.4759 13.1871 19.3694 13.0612 19.2748C12.3094 18.7085 11.4432 18.6047 10.487 18.9664C9.96219 19.1645 9.55179 19.5265 9.29904 20.0132Z"
				fill="#172B49"
			></path>
			<path
				d="M12.4851 25.4489C11.4389 24.4846 10.3852 23.3547 10.2769 21.6036C10.2355 20.9323 10.3569 20.3011 10.6277 19.779C10.9391 19.1797 11.4436 18.7343 12.0874 18.4909C12.2028 18.447 12.315 18.4122 12.4253 18.3812C11.8594 18.2047 11.1514 18.1717 10.3077 18.4909C9.66393 18.7345 9.15918 19.1799 8.84809 19.779C8.57722 20.3011 8.45556 20.9323 8.49727 21.6036C8.60552 23.3547 9.65921 24.4846 10.7055 25.4489L11.3634 26.045C12.1916 26.7886 12.9434 27.4431 13.6535 28.0382L13.8099 28.1693L13.9661 28.0382C14.2047 27.8383 14.4502 27.6285 14.6997 27.4143C14.2069 26.9905 13.693 26.5389 13.143 26.0453L12.4851 25.4489Z"
				fill="white"
			></path>
			<path
				d="M13.8095 28.678C13.6933 28.678 13.5771 28.6383 13.4825 28.5588L13.3261 28.4275C12.6078 27.8257 11.8543 27.17 11.0231 26.4232L10.3634 25.8258C9.2767 24.824 8.11054 23.5943 7.98938 21.6347C7.94221 20.8717 8.08298 20.149 8.39655 19.5447C8.76649 18.8321 9.36483 18.303 10.1275 18.0148C11.7518 17.4008 12.9522 17.9189 13.6732 18.4619C13.7191 18.4967 13.7641 18.5322 13.8095 18.5699C13.8544 18.5324 13.8999 18.4964 13.946 18.4617C14.6663 17.9182 15.8662 17.4005 17.4914 18.0148C18.2539 18.303 18.8527 18.8321 19.2227 19.5447C19.5358 20.149 19.6768 20.8717 19.6296 21.6347C19.5084 23.5946 18.3425 24.824 17.2588 25.8231L16.5976 26.4217C15.7662 27.1683 15.0129 27.8239 14.2926 28.428L14.1367 28.5588C14.0419 28.6383 13.9257 28.678 13.8095 28.678ZM9.29904 20.0132C9.07038 20.4538 8.96858 20.9931 9.00409 21.5723C9.10191 23.1558 10.068 24.1713 11.0491 25.0753L11.7039 25.6682C12.4594 26.3467 13.1506 26.95 13.8092 27.5057C14.4694 26.9488 15.1604 26.3455 15.9164 25.6669L16.5723 25.0725C17.5505 24.1713 18.5163 23.1558 18.6142 21.5723C18.6502 20.9934 18.5481 20.4541 18.3194 20.0132C18.0672 19.5268 17.6563 19.1645 17.1314 18.9664C16.1751 18.6047 15.3088 18.708 14.5573 19.2748C14.4309 19.3694 14.3142 19.4759 14.1789 19.6191C14.0826 19.7209 13.9493 19.7785 13.8092 19.7785C13.6692 19.7785 13.5356 19.7209 13.4398 19.6194C13.3038 19.4759 13.1871 19.3694 13.0612 19.2748C12.3094 18.7085 11.4432 18.6047 10.487 18.9664C9.96219 19.1645 9.55179 19.5265 9.29904 20.0132Z"
				fill="#172B49"
			></path>
		</svg>
	)
}
