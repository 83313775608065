import React, { useRef } from 'react'

import moment from 'moment'

import {
	OfferReservation,
	useOfferReservation
} from '@coq-trotteur/coq-common-components/Business/Reservation/OfferReservation/OfferReservation'
import { OfferReservationActions } from '@coq-trotteur/coq-common-components/Business/Reservation/OfferReservationActions/OfferReservationActions'
import { ReservationDatePickerModal } from '@coq-trotteur/coq-common-components/Business/Reservation/ReservationDatePicker/ReservationDatePickerModal'
import { DeliveryModal } from '@coq-trotteur/coq-common-components/Business/Reservation/DeliveryModal/DeliveryModal'

import {
	TReservationTicket,
	TServiceReservationTicket
} from '@coq-trotteur/coq-types'
import { useModal } from '@coq-trotteur/coq-common-components/Display/Modal/Modal'
import { Button } from '@coq-trotteur/coq-common-components/Atoms/Button/Button'

import { TReservationComponent } from './Reservation.types'

import ReservationAdvantages from './ReservationAdvantages/ReservationAdvantages'

import BoutiqueItemSubtitle from '../BoutiqueItemSubtitle/BoutiqueItemSubtitle'

import './Reservation.scss'

const Reservation: TReservationComponent = ({
	marketItem,
	activity,
	handleNewReservation
}) => {
	const goToPaymentRef = useRef<boolean>()

	const offerReservation = useOfferReservation(
		marketItem,
		activity,
		fetchQuotas
	)

	const deliveryModal = useModal()
	const datePickerModal = useModal()

	return (
		<div style={{ marginBottom: 16 }}>
			<BoutiqueItemSubtitle errors={offerReservation.errors} />

			{marketItem.type === 'product' ? (
				<DeliveryModal
					{...deliveryModal}
					product={marketItem}
					handleSelect={(delivery: any) => {
						handleNewReservation(
							cleanReservation({
								...offerReservation.ticket,
								// @ts-ignore
								delivery
							}),
							goToPaymentRef.current
						)
					}}
					ticket={offerReservation.ticket}
				/>
			) : marketItem.service_availability.type !== 'time_duration' ? (
				<>
					<ReservationDatePickerModal
						{...datePickerModal}
						activity={activity}
						date={offerReservation.date}
						onSelect={(date) => {
							offerReservation.setErrors({
								...offerReservation.errors,
								errors: {
									...offerReservation.errors.errors,
									date: false
								}
							})
							offerReservation.setDate(date)
							datePickerModal.close()
						}}
						services={[marketItem]}
						tickets={[offerReservation.ticket as TServiceReservationTicket]}
					/>
					<div>
						<Button
							error={offerReservation.errors.errors.date}
							onClick={() => {
								datePickerModal.open()
							}}
							style={{ marginLeft: 0 }}
						>
							{offerReservation.date
								? moment(offerReservation.date).format('DD/MM/YYYY')
								: 'Choisir la date'}
						</Button>
					</div>
				</>
			) : null}

			<OfferReservation
				{...offerReservation}
				offer={marketItem}
				fetchQuotas={fetchQuotas}
				handlePickDate={() => {
					datePickerModal.open()
				}}
				config={{
					hideTitle: true,
					autoHideOptions: false,
					hideUniquePricing: true,
					optionsTheme: 'normal',
					pricingsTheme: 'in-box',
					hideInformations: true
				}}
				activity={activity}
			/>

			<div style={{ marginTop: 30 }}>
				<OfferReservationActions
					fetchQuotas={fetchQuotas}
					handleAddToBasket={() => {
						if (!offerReservation.checkErrors()) return

						if (marketItem.type === 'product') {
							goToPaymentRef.current = false
							deliveryModal.open()
						} else {
							handleNewReservation(
								cleanReservation(offerReservation.ticket),
								false
							)
						}
					}}
					handleReserve={() => {
						if (!offerReservation.checkErrors()) return

						if (marketItem.type === 'product') {
							goToPaymentRef.current = true
							deliveryModal.open()
						} else {
							handleNewReservation(
								cleanReservation(offerReservation.ticket),
								true
							)
						}
					}}
					setTicket={offerReservation.setTicket}
					ticket={offerReservation.ticket}
					offer={marketItem}
					{...offerReservation}
				/>
			</div>

			<ReservationAdvantages
				marketItem={marketItem}
				handleOpenDeliveryModal={() => {
					deliveryModal.open()
				}}
				ticket={offerReservation.ticket}
				onTicketChange={offerReservation.setTicket}
			/>
		</div>
	)
}

export default Reservation

export const fetchQuotas = async (data) => {
	return fetch(`${process.env.GATSBY_API_URI}/service_quotas`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json;charset=UTF-8',
			'x-universe-token': process.env.GATSBY_CLIENT_TOKEN
		},
		body: JSON.stringify(data)
	}).then((response) => response.json())
}

const cleanReservation = (reservation: TReservationTicket) => {
	if (reservation.content.unique === false) {
		return {
			...reservation,
			content: {
				...reservation.content,
				list: reservation.content.list.filter(({ quantity }) => quantity > 0)
			}
		}
	}

	return reservation
}
