import React from 'react'
import DecoratedHeading from '~components/Common/DecoratedHeading/DecoratedHeading'
import Description from '~components/Common/Description/Description'
import { useBoutiqueItemContext } from '../../BoutiqueItem'

import './BoutiqueItemDescription.scss'

const BoutiqueItemDescription = ({ typo }) => {
	const item = useBoutiqueItemContext()

	return (
		<div className="BoutiqueItemDescription">
			<DecoratedHeading h={2} style={{ ...typo?.h3 }}>
				Description
			</DecoratedHeading>

			<Description
				description={item.description}
				lengthBreakPoint={200}
				style={{ ...typo?.p }}
			/>

			{item.pricing.note && (
				<Description
					description={item.pricing.note}
					lengthBreakPoint={200}
					style={{ ...typo?.p }}
				/>
			)}

			{item.note && (
				<div className="restriction" style={{ ...typo?.p }}>
					<u>Restrictions / contre-indications</u>
					{' : '}
					<Description
						description={item.note}
						lengthBreakPoint={200}
						style={{ ...typo?.p }}
					/>
				</div>
			)}
		</div>
	)
}

export default BoutiqueItemDescription
