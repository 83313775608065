import { TMarketItem } from '~types/Market.types'

export const getMarketItemGalleryWithMiniatureFirst = (item: TMarketItem) =>
	item
		? [
				item.miniature,
				...(item?.gallery?.filter(({ url }) => url !== item.miniature?.url) ||
					[])
		  ]
				.filter((_) => _ !== null)
				.filter((_) => !!_?.url)
		: null
