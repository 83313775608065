import React from 'react'
import Price from '~components/Common/Price/Price'
import MarketItemTags from './MarketItemTags/MarketItemTags'

import { useBoutiqueItemContext } from '../../BoutiqueItem'

import './BoutiqueItemDetailsHeader.scss'

const BoutiqueItemDetailsHeader = ({ total = 0, typo }) => {
	const item = useBoutiqueItemContext()

	return (
		<div className="BoutiqueItemDetailsHeader">
			<ItemPrice item={item} total={total} style={{ ...typo?.p }} />

			<div className="top"></div>
			<header>
				<div>
					<h1
						style={{
							fontFamily: typo?.h2?.fontFamily,
							fontStyle: typo?.h2.italic ? 'italic' : ''
						}}
					>
						{item.title}
					</h1>
				</div>

				<MarketItemTags tags={item.informations.labels} />
			</header>
		</div>
	)
}

export default BoutiqueItemDetailsHeader

const ItemPrice = ({ item, total, style }) => {
	return (
		<span style={style}>
			{total ? (
				<Price value={total} />
			) : item.pricing.unique ? (
				<Price value={item.pricing.basePrice} />
			) : (
				<>
					À partir de{' '}
					<Price value={Math.min(...item.pricing.list.map((i) => i.price))} />
				</>
			)}
		</span>
	)
}
