import React from 'react'
import { Router } from '@reach/router'

import withTemplate from '~components/Template/withTemplate'
import BoutiqueItem from '~components/Pages/BoutiqueItem/BoutiqueItem'

const BoutiquePage = (props) => {
	return (
		<Router basepath="/a">
			{/* @ts-ignore */}
			<BoutiqueItem {...props} path="/:id" />
		</Router>
	)
}

export default withTemplate(BoutiquePage)
