import React from 'react'

import './MarketItemTags.scss'

const MarketItemTag = ({ tag }: { tag: string }) => {
	const items = [
		{ name: `Fait main`, value: 'handmade' },
		{ name: `Fait maison`, value: 'homemade' },
		{ name: `Fait avec amour`, value: 'makewithlove' },
		{ name: `Eco-responsable`, value: 'environmentally-friendly' },
		{
			name: `Ingrédients issus en partie de l'agriculture biologique`,
			value: 'bio'
		},
		{ name: `Savoir-faire ancestral`, value: 'ancestral' },
		{ name: `Spécialité locale`, value: 'local-speciality' },
		{ name: `Labellisé Bio`, value: 'label-bio' },
		{ name: `Labellisé FSC`, value: 'label-fsc' },
		{ name: `Labellisé Oeko-Tex`, value: 'label-oeko-tex' },
		{ name: `Labellisé Vegan`, value: 'label-vegan' },
		{ name: `Labellisé Cruelty Free`, value: 'label-cruelty-free' },
		{
			name: `Labellisé Entreprise du Patrimoine Vivant`,
			value: 'label-living-heritage'
		},
		{ name: `Labellisé Cosmebio`, value: 'label-cosmebio' },
		{ name: `Labellisé La Clef Verte`, value: 'label-clef-verte' },
		{ name: `Ecolabel Européen`, value: 'european-ecolabel' },
		{
			name: `Labellisé Vignobles et découvertes`,
			value: 'label-vignoble-découverte'
		},
		{
			name: `Chateaux et Hotels collection`,
			value: 'castel-hostel-collection'
		},
		{ name: `Table remarquable`, value: 'table-remarquable' },
		{ name: `Maître restaurateur`, value: 'maitre-restaurateur' },
		{ name: `Relais et Châteaux`, value: 'relais-chateaux' },
		{
			name: `Meilleur ouvrier de France`,
			value: 'meilleurs-ouvrier'
		}
	]

	const label = items.find(({ value }) => value === tag)

	if (!label) return null
	return <div className="MarketItemTag">{label.name}</div>
}

const MarketItemTags = ({ tags }: { tags: string[] }) => (
	<div className="MarketItemTags">
		{tags?.map((tag) => (
			<MarketItemTag tag={tag} key={tag} />
		))}
	</div>
)

export default MarketItemTags
