import React from 'react'

const MagicIcon = () => (
	<svg
		width="30"
		height="30"
		viewBox="0 0 30 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M29.6481 11.4883L27.0179 7.96396L28.4304 3.79838C28.898 2.41972 27.5799 1.10259 26.2017 1.5697L22.0362 2.98229L18.5118 0.351938C17.3452 -0.518126 15.6883 0.326098 15.7033 1.77859L15.7017 6.18053L12.1678 8.72017C10.9798 9.56129 11.2706 11.4012 12.6611 11.8334L14.3333 12.3533L0.687419 25.999C-0.228348 26.9132 -0.229755 28.3979 0.686892 29.313C1.60225 30.229 3.08515 30.2295 4.0008 29.3131L17.647 15.667L18.1669 17.3391C18.598 18.7264 20.4376 19.0225 21.2777 17.8359L23.8197 14.2986L28.2216 14.297C29.6733 14.3133 30.5183 12.6551 29.6481 11.4883ZM3.1721 28.4847C2.71478 28.9424 1.97391 28.9432 1.51524 28.4842C1.06137 28.0311 1.05381 27.2889 1.51559 26.8279L15.5973 12.7462L16.861 13.1391L17.2538 14.4029L3.1721 28.4847ZM28.2245 13.1311L23.5189 13.1328C23.3303 13.1329 23.1534 13.2179 23.0434 13.3709L20.3238 17.1554C20.0419 17.5537 19.4299 17.4552 19.2857 16.9913L18.4805 14.4017L18.4792 14.3975L18.4778 14.3931L17.8884 12.4973C17.8312 12.3131 17.687 12.169 17.5029 12.1118L13.0087 10.7144C12.5476 10.5711 12.4432 9.96067 12.848 9.67403L16.6292 6.95672C16.7823 6.84668 16.8672 6.66979 16.8672 6.48123L16.869 1.77566C16.869 1.77308 16.869 1.77056 16.8689 1.76798C16.8628 1.28189 17.4208 1.00022 17.811 1.29108L21.5822 4.10559C21.7368 4.22097 21.9382 4.25284 22.1207 4.19091L26.5779 2.67941C27.0366 2.52367 27.4772 2.96061 27.3207 3.42209L25.8092 7.87929C25.7472 8.06193 25.7791 8.26331 25.8945 8.41789L28.7088 12.1888C28.9919 12.5685 28.7384 13.1311 28.2245 13.1311Z"
			fill="#172B49"
		/>
		<path
			d="M25.7883 11.9592L22.5777 11.9604C22.449 11.9604 22.3283 12.0184 22.2532 12.1228L20.3977 14.705C20.2054 14.9767 19.7877 14.9095 19.6894 14.593L19.14 12.8261L19.1391 12.8233L19.1382 12.8202L18.736 11.5267C18.697 11.4011 18.5986 11.3027 18.473 11.2637L15.4067 10.3103C15.092 10.2125 15.0208 9.79605 15.297 9.60047L17.8769 7.74649C17.9813 7.67141 18.0393 7.55072 18.0393 7.42207L18.0405 4.21151C18.0405 4.20975 18.0405 4.20803 18.0405 4.20627C18.0363 3.87462 18.417 3.68244 18.6832 3.88089L21.2563 5.8012C21.3617 5.87991 21.4991 5.90166 21.6237 5.85941L24.6648 4.82813C24.9777 4.72187 25.2783 5.01999 25.1715 5.33485L24.1403 8.37595C24.098 8.50056 24.1198 8.63796 24.1985 8.74342L26.1187 11.3163C26.3118 11.5753 26.1389 11.9592 25.7883 11.9592Z"
			fill="#FFEDBD"
		/>
	</svg>
)
export default MagicIcon
