import React, { useState } from 'react'

const PStyle = {
	margin: '10px 0'
}

const Description = ({
	description,
	lengthBreakPoint = 550,
	style
}: {
	description: string
	lengthBreakPoint?: number
	style: any
}) => {
	const [showMore, setShowMore] = useState(false)
	const formatText = (text, truncated = false) => {
		const split = text.split('\n').filter((l) => l.length !== 0)
		if (!truncated)
			return split.map((p, i) => (
				<p key={i} style={{ ...style, ...PStyle }}>
					{p}
				</p>
			))

		return split.map((p, i) =>
			i === split.length - 1 ? (
				<p key={i} style={{ ...style, ...PStyle }}>
					{p}...{' '}
					<span
						role="button"
						style={{ cursor: 'pointer', textDecoration: 'underline' }}
						onClick={() => setShowMore(true)}
						onKeyDown={() => setShowMore(true)}
					>
						Voir plus
					</span>
				</p>
			) : (
				<p key={i} style={{ ...style, ...PStyle }}>
					{p}
				</p>
			)
		)
	}

	if (!description) return null
	if (description.length < lengthBreakPoint || showMore)
		return (
			<>
				{formatText(description)}
				{showMore && (
					<span
						role="button"
						style={{ cursor: 'pointer', textDecoration: 'underline' }}
						onClick={() => setShowMore(false)}
						onKeyDown={() => setShowMore(false)}
					>
						Voir moins
					</span>
				)}
			</>
		)

	return (
		<div className="Description">
			{formatText(description.substr(0, lengthBreakPoint), true)}
		</div>
	)
}
export default Description
