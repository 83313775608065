import React, { useEffect, useState } from 'react'
import { navigate } from '@reach/router'

import ArrowIcon from '~components/Icons/ArrowIcon'
import { getBoutiqueItem } from '~queries/boutique.queries'
import Container from '~components/Common/Container/Container'

import BoutiqueItemGallery from './BoutiqueItemGallery/BoutiqueItemGallery'
import BoutiqueItemDetails from './BoutiqueItemDetails/BoutiqueItemDetails'

import './BoutiqueItem.scss'
import useWebsite from '~hooks/useWebsite'
import { transformTypoToStyles } from '~utils/misc'

const boutiqueItemContext = React.createContext(null)

export const useBoutiqueItemContext = () =>
	React.useContext(boutiqueItemContext)

const BackButton = ({ location }) => (
	<button
		className="BackButton"
		onClick={() => {
			if (location.state.fromResult) {
				history.back()
			} else {
				navigate('/')
			}
		}}
	>
		<ArrowIcon direction="left" />
		<span>Revenir aux résultats</span>
	</button>
)

const BoutiqueItem = ({ location }) => {
	const { theme } = useWebsite()

	const typo = transformTypoToStyles(theme?.typo)

	return (
		<Container>
			<div className="BoutiqueItem" style={typo.p}>
				<div>
					<BackButton location={location} />
					<BoutiqueItemGallery />
				</div>
				<BoutiqueItemDetails />
			</div>
		</Container>
	)
}

const BoutiqueItemContainer = ({ location, id }) => {
	const [item, setItem] = useState(null)

	useEffect(() => {
		getBoutiqueItem(id).then(setItem)
	}, [])

	if (!item) return null

	return (
		<boutiqueItemContext.Provider value={item}>
			<BoutiqueItem location={location} />
		</boutiqueItemContext.Provider>
	)
}

export default BoutiqueItemContainer
