import React from 'react'

const VertueuxIcon = () => (
	<svg
		width="20"
		height="27"
		viewBox="0 0 20 27"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5712 14.8079H19.1007C19.4766 14.8079 19.7813 15.1126 19.7813 15.4884V18.9231C19.7813 19.2989 19.4766 19.6036 19.1008 19.6036H18.4058L16.502 26.5005C16.4205 26.7956 16.152 27 15.8459 27H3.9354C3.62928 27 3.3608 26.7956 3.27934 26.5005L1.37555 19.6036H0.680559C0.304686 19.6036 0 19.2989 0 18.9231V15.4884C0 15.1126 0.304686 14.8079 0.680559 14.8079H9.21007V9.5777H10.5712V14.8079ZM18.4202 16.169H1.36112V18.2426H18.4202V16.169ZM4.45351 25.6389H15.3278L16.9937 19.6036H2.78757L4.45351 25.6389Z"
			fill="#172B49"
		/>
		<path
			d="M4.1447 2.37219C4.50632 1.67607 5.09229 1.15873 5.84004 0.876125C7.47539 0.25757 8.67559 0.773177 9.39392 1.31445C9.59434 1.46527 9.76015 1.62502 9.90751 1.78103C10.0549 1.62502 10.2204 1.46527 10.4208 1.31445C11.1397 0.773177 12.3393 0.257281 13.9747 0.876125C14.7224 1.15902 15.3087 1.67635 15.67 2.37219C15.9849 2.97864 16.1259 3.71168 16.0775 4.49143C15.9518 6.52531 14.7279 7.83769 13.513 8.95772L12.7488 9.6501C11.7865 10.5138 10.9133 11.2739 10.0886 11.9651L9.90722 12.1174L9.72555 11.9651C8.90081 11.2739 8.02762 10.5138 7.06561 9.6501L6.30143 8.95772C5.08623 7.83769 3.86239 6.52531 3.73666 4.49143C3.68879 3.71168 3.83009 2.97864 4.1447 2.37219Z"
			fill="#C9F8FF"
		/>
		<path
			d="M9.60098 8.16C8.1185 7.08498 6.68742 5.69185 6.53403 3.73971C6.47528 2.99129 6.64731 2.28771 7.03112 1.70564C7.47228 1.03749 8.18713 0.540944 9.09935 0.269698C9.26294 0.220708 9.42195 0.181958 9.57815 0.147361C8.7764 -0.0494312 7.77307 -0.0862431 6.57765 0.269698C5.66544 0.541221 4.95023 1.03777 4.50943 1.70564C4.12562 2.28771 3.95323 2.99129 4.01234 3.73971C4.16572 5.69185 4.5185 7.08498 6.00098 8.16L6.80098 9.12C7.97458 9.94896 8.99483 11.3366 10.001 12L11.5401 11.0592L11.7614 10.9131C12.0995 10.6903 11.6474 10.3189 12.001 10.08C11.3027 9.60753 11.5802 9.67024 10.801 9.12L9.60098 8.16Z"
			fill="white"
		/>
		<path
			d="M9.90413 12.7084C9.76917 12.7084 9.63421 12.6622 9.52434 12.5699L9.34267 12.4174C8.50841 11.7184 7.63321 10.9568 6.66774 10.0894L5.90154 9.39555C4.63934 8.23198 3.28486 6.80368 3.14414 4.52757C3.08935 3.64141 3.25285 2.80196 3.61707 2.10007C4.04674 1.27244 4.74171 0.657927 5.62759 0.323129C7.51411 -0.390011 8.90838 0.211818 9.74581 0.842484C9.79916 0.882856 9.85135 0.924093 9.90413 0.967926C9.95632 0.924382 10.0091 0.882568 10.0627 0.842196C10.8993 0.210953 12.293 -0.3903 14.1807 0.323129C15.0663 0.657927 15.7618 1.27244 16.1915 2.10007C16.5551 2.80196 16.7189 3.64141 16.6641 4.52757C16.5234 6.80397 15.1692 8.23198 13.9105 9.39238L13.1425 10.0876C12.1768 10.9548 11.3019 11.7164 10.4653 12.418L10.2842 12.5699C10.174 12.6622 10.0391 12.7084 9.90413 12.7084ZM4.66529 2.64422C4.3997 3.15608 4.28147 3.78242 4.32271 4.45519C4.43633 6.29442 5.55838 7.47385 6.69802 8.52381L7.45845 9.21244C8.33597 10.0006 9.13879 10.7013 9.90384 11.3467C10.6706 10.6999 11.4732 9.99911 12.3512 9.211L13.1131 8.52064C14.2493 7.47385 15.3711 6.29442 15.4847 4.45519C15.5265 3.78271 15.408 3.15637 15.1424 2.64422C14.8494 2.07931 14.3721 1.65857 13.7625 1.42845C12.6517 1.0083 11.6456 1.12826 10.7727 1.78661C10.6259 1.89648 10.4904 2.02019 10.3332 2.18658C10.2213 2.30481 10.0665 2.37171 9.90384 2.37171C9.7412 2.37171 9.58605 2.30481 9.47474 2.18687C9.31672 2.02019 9.18118 1.89648 9.03498 1.78661C8.16179 1.12884 7.15566 1.0083 6.04515 1.42845C5.43553 1.65857 4.95886 2.07902 4.66529 2.64422Z"
			fill="#172B49"
		/>
	</svg>
)
export default VertueuxIcon
