import {
	TMarketItem,
	TProductReservationTicket,
	TReservationTicket
} from '@coq-trotteur/coq-types'
import React from 'react'
import { capitalize, formatPrice } from '~utils/misc'

import { Checkbox } from '@coq-trotteur/coq-common-components'

import CadenasIcon from './icons/CadenasIcon'
import { GiftIcon } from './icons/GiftIcon'
import MagicIcon from './icons/MagicIcon'
import VertueuxIcon from './icons/VertueuxIcon'

import './ReservationAdvantages.scss'

const ReservationAdvantages = ({
	marketItem,
	handleOpenDeliveryModal,
	ticket,
	onTicketChange
}: {
	marketItem: TMarketItem
	handleOpenDeliveryModal: () => void
	ticket: TReservationTicket
	onTicketChange: (ticket: TReservationTicket) => void
}) => {
	return (
		<div className="ReservationAdvantages">
			{marketItem.type === 'product' && (
				<BoutiqueItemReservationOtherDelivery
					item={marketItem}
					data={{}}
					openMarketItemdeliveryModal={handleOpenDeliveryModal}
				/>
			)}

			{/* {marketItem.type === 'product' && marketItem.delivery.takeAway.enabled && (
				<div>
					<div className="icon">
						<GiftIcon />
					</div>
					Retrait sur place gratuit
				</div>
			)} */}

			{marketItem.type === 'product' &&
				marketItem.giftPackage.available === 'gift-package' && (
					<div
						role="button"
						style={{ cursor: 'pointer' }}
						onClick={() => {
							//

							//
							onTicketChange({
								...ticket,
								giftPackage: {
									selected: !(ticket as TProductReservationTicket).giftPackage
										.selected
								}
							} as TProductReservationTicket)
						}}
					>
						<div className="icon">
							<GiftIcon />
						</div>
						Optionnel : cochez si vous voulez que le vendeur vous fournisse un
						emballage cadeau (gratuit){' '}
						<Checkbox
							value={(ticket as TProductReservationTicket).giftPackage.selected}
						/>
					</div>
				)}

			<div>
				<div className="icon">
					<CadenasIcon />
				</div>
				Paiements cryptés et sécurisés
			</div>
			{marketItem.type === 'service' && (
				<div>
					<div className="icon">
						<VertueuxIcon />
					</div>
					Vertueux pour l’écosystème local
				</div>
			)}
		</div>
	)
}

export default ReservationAdvantages

const BoutiqueItemReservationOtherDelivery = ({
	item,
	data,
	openMarketItemdeliveryModal
}) => {
	const goToShippingInformations = () => {
		const div = document.getElementById('shipping-informations')
		window.scrollTo({
			top: div.offsetTop,
			behavior: 'smooth'
		})
	}

	const selectedDeliveryMode = null

	if (data.delivery) {
		const { type } = data.delivery
		return (
			<div>
				<div className="icon">
					<MagicIcon />
				</div>
				Mode de retrait choisi :{' '}
				{type === 'shippingoption'
					? `Livraison par voie postale`
					: type === 'takeaway'
					? 'Retrait sur place'
					: 'Livraison par le vendeur'}
				{', '}
				<u
					role="button"
					onClick={openMarketItemdeliveryModal}
					style={{ cursor: 'pointer' }}
				>
					modifier
				</u>
			</div>
		)
	}

	if (selectedDeliveryMode) {
		const { type } = selectedDeliveryMode

		return (
			<div>
				<div className="icon">
					<MagicIcon />
				</div>
				Mode de retrait choisi :{' '}
				{type === 'shippingoption'
					? `Livraison par voie postale`
					: type === 'takeaway'
					? 'Retrait sur place'
					: 'Livraison par le vendeur'}
				{', '}
				<u
					role="button"
					onClick={openMarketItemdeliveryModal}
					style={{ cursor: 'pointer' }}
				>
					modifier
				</u>
			</div>
		)
	}

	const getMessage = () => {
		const { delivery } = item

		const hasOwndelivery = delivery.ownDelivery.enabled
		const hasShippingOption = !!delivery.shippingOptions.find(
			({ enabled }) => enabled
		)
		const hasTakeAway = delivery.takeAway.enabled

		if (hasTakeAway && !hasOwndelivery && !hasShippingOption) {
			return 'Retrait sur place uniquement'
		}

		if (!hasTakeAway && hasOwndelivery && !hasShippingOption) {
			return (
				<>
					Livraison par le vendeur uniquement,{' '}
					<u
						role="button"
						onClick={goToShippingInformations}
						style={{ cursor: 'pointer', marginLeft: 4 }}
					>
						voir détails
					</u>
				</>
			)
		}

		if (!hasTakeAway && !hasOwndelivery && hasShippingOption) {
			return (
				<>
					Livraison par voie postale uniquement,{' '}
					<u
						role="button"
						onClick={goToShippingInformations}
						style={{ cursor: 'pointer', marginLeft: 4 }}
					>
						voir détails
					</u>
				</>
			)
		}

		const methods = []

		const formatMessage = (methods) => {
			let message = ''

			for (let i = 0; i < methods.length; i++) {
				if (i === 0) {
					//
				} else if (i === methods.length - 1) {
					message += ' ou '
				} else {
					message += ', '
				}

				message += methods[i]
			}

			return capitalize(message)
		}

		if (hasOwndelivery) {
			if (delivery.ownDelivery.pricing === 'yes') {
				methods.push('livraison par le vendeur gratuite')
			} else if (delivery.ownDelivery.pricing === 'no') {
				methods.push('livraison par le vendeur')
			} else {
				methods.push(
					`livraison par le vendeur gratuite à partir de ${formatPrice(
						delivery.ownDelivery.freeAfterOrderPrice
					)}€`
				)
			}
		}
		if (hasShippingOption) {
			methods.push('livraison par voie postale')
		}
		if (hasTakeAway) {
			methods.push('retrait sur place gratuit')
		}

		return (
			<span>
				{formatMessage(methods)},{' '}
				<u
					role="button"
					onClick={goToShippingInformations}
					style={{ cursor: 'pointer' }}
				>
					voir détails
				</u>
			</span>
		)
	}

	const message = getMessage()

	return (
		<div>
			<div className="icon">
				<MagicIcon />
			</div>
			{message}
		</div>
	)
}
