import React, { useRef, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { getPictureURL } from '~utils/cloudinary'

import { useBoutiqueItemContext } from '../BoutiqueItem'

import { getMarketItemGalleryWithMiniatureFirst } from './BoutiqueItemGallery.utils'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './BoutiqueItemGallery.scss'

const BoutiqueItemGallery = () => {
	const item = useBoutiqueItemContext()
	const miniaturesRef = useRef(null)

	const [currentIndex, setCurrentIndex] = useState(0)

	const gallery = getMarketItemGalleryWithMiniatureFirst(item)

	if (!gallery?.length) return null

	return (
		<div className="BoutiqueItemGallery">
			<div className="MarketItemGallery">
				<Carousel
					className="carousel"
					key="A"
					emulateTouch={true}
					showIndicators={false}
					showThumbs={false}
					infiniteLoop={gallery.length > 1}
					showStatus={false}
					selectedItem={currentIndex}
					onChange={(e) => {
						setCurrentIndex(e)

						miniaturesRef.current.scrollTo({
							left: (e - 1) * 98,
							behavior: 'smooth'
						})

						return e
					}}
				>
					{gallery.length === 0
						? [
								<div key="empty">
									<img src={`/images/empty-big.jpg`} alt="galerie" />
								</div>
						  ]
						: gallery.map((picture) => (
								<div
									key={picture?.public_id}
									style={{
										display: 'flex',
										alignItems: 'center',

										height: '100%'
									}}
								>
									<img
										src={getPictureURL(picture, {
											h: 430,
											c: 'limit'
										})}
										alt="galerie"
										style={{
											objectFit: 'contain',
											margin: 'auto',
											maxWidth: '100%',
											maxHeight: '100%'
										}}
									/>
								</div>
						  ))}
				</Carousel>
			</div>

			<div className="MarketItemGalleryMiniatures" ref={miniaturesRef}>
				<div>
					{gallery.map((picture, i) => (
						<button
							key={i}
							className={`MarketItemGalleryMiniature ${
								i === currentIndex ? 'active' : ''
							}`}
							onClick={() => {
								setCurrentIndex(i)

								miniaturesRef.current.scrollTo({
									left: (i - 1) * 98,
									behavior: 'smooth'
								})
							}}
						>
							<img
								className="MarketItemGalleryMiniature"
								src={getPictureURL(picture, {
									h: 90,
									w: 90,
									c: 'fill',
									q: 95
								})}
								alt="galerie"
							/>
						</button>
					))}
				</div>
			</div>
		</div>
	)
}

export default BoutiqueItemGallery
