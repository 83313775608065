import React from 'react'

import './DecoratedHeading.scss'

type TDecoratedHeadingProps = {
	children: any
	h: 1 | 2 | 3
	style?: any
}

const DecoratedHeading = ({ children, h, style }: TDecoratedHeadingProps) => {
	return React.createElement(
		`h${h}`,
		{ className: 'DecoratedHeading', style },
		<span>{children}</span>
	)
}

export default DecoratedHeading
