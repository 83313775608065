import React from 'react'
import { navigate } from 'gatsby'

import { useBoutiqueItemContext } from '../BoutiqueItem'

import BoutiqueItemDescription from './BoutiqueItemDescription/BoutiqueItemDescription'
// import BoutiqueItemReservation from './BoutiqueItemReservation/BoutiqueItemReservation'
import BoutiqueItemInformations from './BoutiqueItemInformations/BoutiqueItemInformations'
import BoutiqueItemDetailsHeader from './BoutiqueItemDetailsHeader/BoutiqueItemDetailsHeader'

import { useBasketContext } from '~contexts/basketContext'

import useWebsite from '~hooks/useWebsite'
import { transformTypoToStyles } from '~utils/misc'
import Reservation from './Reservation/Reservation'

import './BoutiqueItemDetails.scss'

const BoutiqueItemDetails = () => {
	const item = useBoutiqueItemContext()
	const basket = useBasketContext()
	const { theme } = useWebsite()

	if (!item) return null

	const handleNewReservation = (reservation, goToBasket) => {
		basket.addItem({
			...reservation,
			item: {
				...item
			}
		})

		if (goToBasket) {
			navigate('/mon-panier')
		} else {
			history.back()
		}
	}
	const typo = transformTypoToStyles(theme?.typo)

	return (
		<div className="BoutiqueItemDetails">
			<BoutiqueItemDetailsHeader typo={typo} />

			<Reservation
				marketItem={item}
				activity={item.activities[0]}
				handleNewReservation={handleNewReservation}
			/>

			{/* <BoutiqueItemReservation
				handleNewReservation={handleNewReservation}
				onTotalChange={() => {}}
				typo={typo}
			/> */}
			<BoutiqueItemDescription typo={typo} />
			<BoutiqueItemInformations typo={typo} />
		</div>
	)
}

export default BoutiqueItemDetails
